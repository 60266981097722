import React from "react"
import Layout from "../../components/layout/layout"
import SmallHero from "../../components/layout/smallHero"
import "../mystyles.scss"

import { Helmet } from "react-helmet"

export default function Benvoles() {
  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Grands Pieds</title>
      </Helmet>
      <SmallHero title="Course des Grands Pieds" color="is-warning" />
      <article className="section">
        <div className="container">
          <h1 className="title">Inscription</h1>
          <p>
            Les inscriptions se font via le formulaire suivant :{" "}
            <a href="https://forms.gle/DGR9tJVyi9W9WrNTA">
              Formulaire d'inscription
            </a>
          </p>
          <br />
          <h1 className="title">Informations pour les bénévoles</h1>
          <p>
            Grand projet, demande une grande organisation et forcément des
            bénévoles motivés ! Il s’agit de la première édition de cette
            course, c’est pourquoi nous espérons un large nombre de bénévoles,
            afin de créer les meilleures conditions possibles pour cet
            évènement. Dans l’idéal, nous serions heureux de trouver 100
            personnes qui ont envie d’aider et qui se répartiraient sur les
            diverses tranches horaires.
            <br />
            <br />
            Votre poste exact vous sera communiqué ultérieurement. Veuillez
            simplement vous inscrire pour l’heure qui vous arrange. Nous vous
            demanderons d’être sur place 15min avant le début de votre shift
            dans le but que la transition se passe au mieux.
            <br />
            <br />
            PS : Le nombre de personnes par tranche horaire est limité pour
            permettre une répartition égale et faciliter l’organisation.
            ATTENTION ! Votre inscription est définitive une fois que vous la
            validez. S’il-vous-plaît, en cas d’urgence qui empêcherait votre
            participation, veuillez nous contacter à l’adresse mail suivante:{" "}
            <a href="mailto: lacoursedesgrandspieds@gmail.com">
              lacoursedesgrandspieds@gmail.com
            </a>
          </p>
          <br />
        </div>
      </article>
    </Layout>
  )
}
